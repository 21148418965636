import { default as _pricePackages } from './pricePackages.json'

export interface PriceModel {
  compute: number
  storage: number
  function: number
  data_in: number
  data_out: number
  disk: number
  elastic_throughput_unit: number
  ursa_elastic_throughput_unit: number
  flink: number
}
export interface PricePackage {
  hosted: Partial<PriceModel>
  serverless?: Partial<PriceModel>
  'hosted-pro'?: Partial<PriceModel>
  managed: Partial<PriceModel>
  'managed-pro'?: Partial<PriceModel>
}
export type PricePackageKeys = '2023-pricing-package' | '2024-pricing-package'
export const pricePackages = _pricePackages as unknown as Record<PricePackageKeys, PricePackage>
