<template>
  <div class="h-full">
    <div v-if="route.name !== 'OrganizationsPage'">
      <ds-banner v-if="isSubscriptionEndBannerDisplay" :is-closeable="false" class="md:fixed">
        <span class="text-lg">
          The expiration date of your StreamNative subscription is {{ subscriptionEndDate }}. To
          continue using StreamNative Cloud without interruptions, please renew your subscription by
          contacting
          <a href="mailto:sales@streamnative.io" target="_blank"> sales@streamnative.io </a>
          .
        </span>
      </ds-banner>
      <ds-banner v-if="isDiscountEndBannerDisplay" :is-closeable="false" class="md:fixed">
        <span class="text-lg">
          Applied discount is exahausted, to ensure uninterrupted service please configure
          <a id="billing-portal-link" target="_blank">payment method</a> or contact
          <a href="mailto:sales@streamnative.io" target="_blank"> sales@streamnative.io.</a>
        </span>
      </ds-banner>
      <ds-banner
        v-if="
          activeOrganization?.metadata?.name &&
          getAppliedDiscountValueCent &&
          !isDiscountEndBannerDisplay
        "
        :id="`intro-discount-banner-${activeOrganization?.metadata?.name}`"
        :is-closeable="true"
        class="md:fixed"
        @close="onBannerClose"
      >
        <span class="text-lg">
          Welcome to StreamNative! You are starting
          <b>${{ Math.floor(getAppliedDiscountValueCent / 100) }}</b> free trial credit, which is
          applied to <b>{{ activeOrganization?.metadata?.name }}</b> org,
          <b>{{ discountCreditRemainingPretty }}</b> credit is remaining.
        </span>
      </ds-banner>
    </div>
    <metainfo>
      <template #title="{ content }">{{ content ? `${content}` : `StreamNative Cloud` }}</template>
    </metainfo>
    <router-view v-if="!loading" />
    <div v-if="loading" class="absolute indent-1 h-full w-full flex justify-center items-center">
      <DsLoading class="w-12" />
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n'
import { useMeta } from 'vue-meta'
import { useAnalytics } from '@/composables/analytics'
import * as Sentry from '@sentry/vue'
import type { PulsarState } from './composables/usePulsarState'
import { until } from '@vueuse/core'
import { auth } from './auth'
import '/src/stories/scss/nb-base.scss'

export default defineComponent({
  setup() {
    const { locale } = useI18n()
    const router = useRouter()
    const route = useRoute()
    const loading = ref<boolean>(true)
    useMeta({
      title: 'StreamNative Cloud',
      htmlAttrs: { lang: locale.value, amp: true }
    })

    const { user, isLoading } = auth
    const { identifyUser } = useAnalytics()
    const { isSubscriptionEndBannerDisplay, subscriptionEndDate, discountCreditRemainingPretty } =
      useSubscription()

    const {
      activeOrganization,
      getAppliedDiscountValueCent,
      isDiscountEndBannerDisplay,
      openBillingAndPayment,
      isDiscountBannerClosed
    } = useOrganization()

    const onBannerClose = () => {
      isDiscountBannerClosed.value = 'close'
    }

    onBeforeMount(async () => {
      // DO NOT REMOVE THESE AWAITS
      await router.isReady()
      await until(() => !isLoading.value).toBe(true)

      try {
        const urlSearchParams = new URLSearchParams(window.location.search)
        const params = Object.fromEntries(urlSearchParams.entries())
        const pulsarState: PulsarState = {
          organization: params.org ? params.org : undefined,
          instance: params.instance ? params.instance : undefined,
          clusterUid: params.cluster ? params.cluster : undefined,
          tenant: params.tenant ? params.tenant : undefined,
          namespace: params.namespace ? params.namespace : undefined,
          topic: params.topic ? params.topic : undefined
        }

        // below data syncs are needed for some pages initial load,
        // for example, we need to know if is the Function feature enabled
        // from the active cluster info before we can load FunctionsPage
        // so we need to wait for them
        await Promise.all([
          useOrganization().init(pulsarState),
          useInstance().init(pulsarState),
          useCluster().init(pulsarState),
          usePools().init(pulsarState),
          useTenantNamespace().init(pulsarState),
          useTopic().init(pulsarState)
        ]).catch(e => {
          console.error(e)
        })

        // below data syncs are not needed for the page initial load,
        // so we don't need to wait for them
        Promise.all([
          useEnvironment().init(pulsarState),
          useSubscription().init(pulsarState),
          useServiceAccount().init(pulsarState),
          useSecret().init(pulsarState),
          useUser().init(pulsarState)
          // eslint-disable-next-line promise/prefer-await-to-then
        ]).catch(e => {
          console.error(e)
        })

        if (user?.value?.email) {
          Sentry.setUser({
            email: user.value.email,
            id: user.value.sub,
            name: user.value.name
          })
        }

        identifyUser()
      } catch (e) {
        useError(e)
      } finally {
        loading.value = false
      }
    })

    onMounted(() => {
      const billingPortalLink = document.getElementById('billing-portal-link')
      if (billingPortalLink) {
        billingPortalLink.onclick = () => {
          openBillingAndPayment()
        }
      }
    })

    return {
      loading,
      activeOrganization,
      getAppliedDiscountValueCent,
      subscriptionEndDate,
      isSubscriptionEndBannerDisplay,
      isDiscountEndBannerDisplay,
      discountCreditRemainingPretty,
      onBannerClose,
      route
    }
  }
})
</script>
